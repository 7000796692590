import '../App.css';
import React, { useEffect, useMemo, useState, useRef } from "react";
import axios from "axios";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const base_url = process.env.NODE_ENV === "production" ? "https://hooks-28xd.onrender.com" : "http://localhost:4000";

function Whale() {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const [init, setInit] = useState(false);
  const [asciiArt, setAsciiArt] = useState(`
      __         __
     /  \\.-"""-./  \\
     \\    -   -    /
      |   o   o   |
      \\  .-'''-.  /
       '-\\__Y__/-'
          '---'
  `);
  const [inputWord, setInputWord] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [inputPosition, setInputPosition] = useState({ top: "50%", left: "50%" });
  const inputContainerRef = useRef(null);

  const handleMouseMove = (e) => {
    if (!isLoggedIn) {
      const inputRect = inputContainerRef.current.getBoundingClientRect();
      const distance = Math.sqrt(Math.pow(e.clientX - (inputRect.left + inputRect.width / 2), 2) + Math.pow(e.clientY - (inputRect.top + inputRect.height / 2), 2));
      if (distance < 100) {
        setInputPosition({
          top: `${Math.random() * 80 + 10}%`,
          left: `${Math.random() * 80 + 10}%`
        });
      }
    }
  };

  const handleInputChange = (e) => {
    setInputWord(e.target.value);
  };

  const fetchAsciiArt = async () => {
    try {
      const response = await axios.post(`${base_url}/ascii-art/generate-ascii-art`, {
        inputWord
      });
      setAsciiArt(response.data.asciiArt);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });

    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const verifyCookie = async () => {
      if (cookies.token) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    };
    verifyCookie();
  }, [cookies]);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
      background: {
        color: "#000"
      },
      detectRetina: false,
      fpsLimit: 30,
      interactivity: {
        detectsOn: "canvas",
        events: {
          resize: true
        }
      },
      particles: {
        color: {
          value: "#fff"
        },
        number: {
          density: {
            enable: true,
            area: 1080
          },
          limit: 0,
          value: 400
        },
        opacity: {
          animation: {
            enable: true,
            minimumValue: 0.05,
            speed: 0.25,
            sync: false
          },
          random: {
            enable: true,
            minimumValue: 0.05
          },
          value: 1
        },
        shape: {
          type: "circle"
        },
        size: {
          random: {
            enable: true,
            minimumValue: 0.5
          },
          value: 1
        }
      }
    }),
    [],
  );

  return (
    <div className='whale-container'>
      <div className='nav-buttons'>
        <button className='food-button' onClick={() => navigate("/home")}>Home</button>
        <button className='food-button' onClick={() => navigate("/public-rank")}>Public Rank</button>
      </div>
      <div ref={inputContainerRef} className='input-container' style={{ position: "absolute", top: inputPosition.top, left: inputPosition.left, transform: "translate(-50%, -50%)", transition: "top 0.5s, left 0.5s" }}>
        <input
          type="text"
          placeholder="Enter a word"
          value={inputWord}
          onChange={handleInputChange}
          disabled={!isLoggedIn}
        />
        <button onClick={fetchAsciiArt} disabled={!isLoggedIn}>Generate ASCII Art</button>
      </div>
      {asciiArt && (
        <TextArt
          label="ASCII art"
          text={asciiArt}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            pointerEvents: "none",
            overflow: "hidden"
          }}
        />
      )}
    </div>
  );
}

function TextArt({ label, text, style }) {
  return (
    <pre
      aria-label={label}
      className="text-art"
      style={style}
    >{text}</pre>
  );
}

export default Whale;