import './App.css';
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Whale from './pages/Whale';
import Tss  from './pages/Tss';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Map from './pages/Map';
import FriendRank from './pages/FriendRank';
import Home from './pages/Home';
import VideoSpawner from './pages/VideoSpawner';
import CustomPage from './pages/CustomPage';
import PublicFriendRank from './pages/PublicFriendRank';
import Sheet from './pages/Sheet';

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Whale />} />
          <Route path="/tss" element={ <Tss />} />
          <Route path="/login" element={ <Login />} />
          <Route path="/signup" element={ <Signup />} />
          <Route path="/map" element={ <Map />} />
          <Route path='/rank' element={ <FriendRank />} />
          <Route path='/home' element={ <Home />} />
          <Route path='/video' element={ <VideoSpawner />} />
          <Route path='/custom' element={ <CustomPage />} />
          <Route path='/public-rank' element={ <PublicFriendRank />} />
          <Route path='/sheet' element={ <Sheet />} />
        </Routes>
    </Router>
  );
}

export default App;