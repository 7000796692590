import '../App.css';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

const base_url = process.env.NODE_ENV === "production" ? "https://hooks-28xd.onrender.com" : "http://localhost:4000";
// TODO: More fun with dynamic head values here
const PublicFriendRank = () => {
    const [friends, setFriends] = useState([]);
    const [friendToBuyPizza, setFriendToBuyPizza] = useState("");

    const sortedFriends = friends.sort((a, b) => b.pizzaCount - a.pizzaCount);

    useEffect(() => {
        const fetchFriends = async () => {
            try {
                const { data } = await axios.get(base_url + "/pizza/");
                if (data.success) {
                    setFriends(data.pizzaPeople);
                    if (data.pizzaPeople.length > 0) {
                        const friendWithLowestPizzaCount = data.pizzaPeople.reduce((prev, curr) => (prev.pizzaCount < curr.pizzaCount ? prev : curr));
                        setFriendToBuyPizza(friendWithLowestPizzaCount.name);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchFriends();
    }, []);

    return (
        <div className='friend-rank'>
            <Helmet>
                <meta property="og:title" content={friendToBuyPizza + " buy a pizza"} />
            </Helmet>
            <h1>Friend Pizza Rankings</h1>
            <div className='friend-list'>
                {sortedFriends.map((friend, index) => (
                    <div key={index} className='friend-item'>
                        <span className='friend-name'>{friend.name}</span>
                        <span className='friend-pizza-count'>{friend.pizzaCount} pizzas</span>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default PublicFriendRank;
