import '../App.css';
import React, { useState } from "react";

const CustomPage = () => {
    const [htmlInput, setHtmlInput] = useState("<h2>Sample HTML</h2><p>This is a sample paragraph.</p>");
    const [isEditing, setIsEditing] = useState(true);

    const toggleEditMode = () => {
        setIsEditing(!isEditing);
    };

    return (
        <div className='custom-page'>
            <h1>Custom Page</h1>
            <button onClick={toggleEditMode}>
                {isEditing ? "Render HTML" : "Edit HTML"}
            </button>
            {isEditing ? (
                <textarea
                    className='html-input'
                    value={htmlInput}
                    onChange={(e) => setHtmlInput(e.target.value)}
                    placeholder="Enter HTML here"
                />
            ) : (
                <div
                    class='html-output'
                    dangerouslySetInnerHTML={{ __html: htmlInput }}
                />
            )}
        </div>
    );
};

export default CustomPage;
