import '../App.css';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

const base_url = process.env.NODE_ENV === "production" ? "https://hooks-28xd.onrender.com" : "http://localhost:4000";

const VideoSpawner = () => {
    const navigate = useNavigate();
    const [cookies, removeCookie] = useCookies([]);
    const [username, setUsername] = useState("");
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");
    const [videoTitle, setVideoTitle] = useState("");
    const [videoDescription, setVideoDescription] = useState("");
    const [videoTags, setVideoTags] = useState("");

    useEffect(() => {
        const verifyCookie = async () => {
            if (!cookies.token) {
                navigate("/login");
            }
            const { data } = await axios.post(
                base_url + "/auth",
                {},
                {
                    withCredentials: true,
                    httpOnly: false,
                    sameSite: "none",
                    secure: true
                }
            );
            const { status, user } = data;
            setUsername(user);
            if (status) {
                toast(`Hello ${user}`, {
                    position: "top-right",
                });
            } else {
                removeCookie("token");
                navigate("/login");
            }
        };
        verifyCookie();
    }, [cookies, navigate, removeCookie, searchParams]);

    const Logout = () => {
        removeCookie("token");
        navigate("/signup");
    };

    const generateVideo = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${base_url}/spawner/generate-video`, {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            setVideoUrl(url);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleDownloadClick = () => {
        setVideoUrl("");
    };

    const publishVideo = async () => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("video", new Blob([await fetch(videoUrl).then(r => r.blob())]), "motivational_video.mp4");
            formData.append("title", videoTitle);
            formData.append("description", videoDescription);
            formData.append("tags", videoTags.split(","));

            const response = await axios.post(`${base_url}/api/upload-video`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            toast(`Video published successfully: ${response.data.videoId}`, {
                position: "top-right",
            });
        } catch (error) {
            console.log(error);
            toast("Error publishing video", {
                position: "top-right",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='video-spawner'>
            <h1>Video Spawner</h1>
            <div className='button-container'>
                <button onClick={generateVideo} disabled={loading}>
                    {loading ? "Generating..." : "Generate Video"}
                </button>
                {videoUrl && (
                    <div>
                        <a
                            href={videoUrl}
                            download="motivational_video.mp4"
                            className="download-link"
                            onClick={handleDownloadClick}
                        >
                            Download Video
                        </a>
                        <div className="publish-container">
                            <input
                                type="text"
                                placeholder="Video Title"
                                value={videoTitle}
                                onChange={(e) => setVideoTitle(e.target.value)}
                            />
                            <textarea
                                placeholder="Video Description"
                                value={videoDescription}
                                onChange={(e) => setVideoDescription(e.target.value)}
                            />
                            <input
                                type="text"
                                placeholder="Video Tags (comma separated)"
                                value={videoTags}
                                onChange={(e) => setVideoTags(e.target.value)}
                            />
                            <button onClick={publishVideo} disabled={loading}>
                                {loading ? "Publishing..." : "Publish to YouTube"}
                            </button>
                        </div>
                    </div>
                )}
                <button onClick={Logout}>Logout</button>
            </div>
        </div>
    );
};

export default VideoSpawner;
