import '../App.css';
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from "react-leaflet";

const base_url = process.env.NODE_ENV === "production" ? "https://hooks-28xd.onrender.com" : "http://localhost:4000";

const Map = () => {
    const navigate = useNavigate();
    const [cookies, removeCookie] = useCookies([]);
    const [username, setUsername] = useState("");
    const [searchParams] = useSearchParams();
    const [mapCenter, setMapCenter] = useState([51.505, -0.09]);
    const [map, setMap] = useState(null);

    useEffect(() => {
        const verifyCookie = async () => {
            if (!cookies.token) {
                navigate("/login");
            }
            const { data } = await axios.post(
                base_url + "/auth",
                {},
                {
                    withCredentials: true,
                    httpOnly: false,
                    sameSite: "none",
                    secure: true
                }
            );
            const { status, user } = data;
            setUsername(user);
            if (status) {
                toast(`Hello ${user}`, {
                    position: "top-right",
                });
            } else {
                removeCookie("token");
                navigate("/login");
            }
        };

        const fetchMapPoints = async () => {
            try {
                const { data } = await axios.get(base_url + "/map/get");
                if (data.success) {
                    setSampleMap(data.mapPoints);
                }
            } catch (error) {
                console.log(error);
            }
        };

        const fetchUserLocation = async () => {
            try {
                const response = await axios.get("https://ipapi.co/json/");
                const { latitude, longitude } = response.data;
                setMapCenter([latitude, longitude]);
                if (map) {
                    map.setView([latitude, longitude], 13);
                }
            } catch (error) {
                console.log(error);
                setMapCenter([51.49634719159713, -0.01635395259475869]);
                if (map) {
                    map.setView([51.49634719159713, -0.01635395259475869], 13);
                }
            }
        };

        verifyCookie();
        fetchMapPoints();
        fetchUserLocation();
    }, [cookies, navigate, removeCookie, searchParams, username, map]);

    const Logout = () => {
        removeCookie("token");
        navigate("/signup");
    };

    const [sampleMap, setSampleMap] = useState([]);

    const [newPinText, setNewPinText] = useState("");
    const [newPinCoords, setNewPinCoords] = useState([0, 0]);

    const handleAddPin = async () => {
        try {
            const { data } = await axios.post(base_url + "/map/store", {
                text: newPinText,
                coords: newPinCoords
            });
            if (data.success) {
                setSampleMap([...sampleMap, data.mapPoint]);
                setNewPinText("");
                setNewPinCoords([0, 0]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleAddPinWithIP = async () => {
        try {
            const response = await axios.get("https://ipapi.co/json/");
            const { latitude, longitude } = response.data;
            const { data } = await axios.post(base_url + "/map/store", {
                text: newPinText,
                coords: [latitude, longitude]
            });
            if (data.success) {
                setSampleMap([...sampleMap, data.mapPoint]);
                setNewPinText("");
            }
        } catch (error) {
            console.log(error);
            const { data } = await axios.post(base_url + "/map/store", {
                text: newPinText,
                coords: [51.49634719159713, -0.01635395259475869]
            });
            if (data.success) {
                setSampleMap([...sampleMap, data.mapPoint]);
                setNewPinText("");
            }
        }
    };

    const handleMapClick = (e) => {
        setNewPinCoords([e.latlng.lat, e.latlng.lng]);
    };

    const MapClickHandler = () => {
        useMapEvents({
            click: handleMapClick,
        });
        return null;
    };

    const handleDeletePin = async (id) => {
        try {
            const { data } = await axios.post(base_url + "/map/delete", { id });
            if (data.success) {
                setSampleMap(sampleMap.filter(point => point._id !== id));
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='box'>
            <div className='map-container'>
                <MapContainer center={mapCenter} zoom={13} scrollWheelZoom={true} whenCreated={setMap}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MapClickHandler />
                    {
                        sampleMap.map((item, index) => (
                            <Marker key={index} position={item.coords}>
                                <Popup>
                                    {item.text}
                                    <br />
                                    <button onClick={() => handleDeletePin(item._id)}>Delete Pin</button>
                                </Popup>
                            </Marker>
                        ))
                    }
                </MapContainer>
            </div>
            <div className='input-container'>
                <input
                    type="text"
                    placeholder="Pin text"
                    value={newPinText}
                    onChange={(e) => setNewPinText(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Latitude"
                    value={newPinCoords[0]}
                    onChange={(e) => setNewPinCoords([parseFloat(e.target.value), newPinCoords[1]])}
                />
                <input
                    type="text"
                    placeholder="Longitude"
                    value={newPinCoords[1]}
                    onChange={(e) => setNewPinCoords([newPinCoords[0], parseFloat(e.target.value)])}
                />
                <button onClick={handleAddPin}>Add Pin</button>
                <button onClick={handleAddPinWithIP}>Add Pin with IP</button>
                <button onClick={Logout}>LOGOUT</button>
            </div>
        </div>
    )
};

export default Map;