import '../App.css';
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import { toast } from "react-toastify";

const base_url = process.env.NODE_ENV === "production" ? "https://hooks-28xd.onrender.com" : "http://localhost:4000";

const Sheet = () => {
    const navigate = useNavigate();
    const [cookies, removeCookie] = useCookies([]);
    const [username, setUsername] = useState("");
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const verifyCookie = async () => {
            if (!cookies.token) {
                navigate("/login");
            }
            const { data } = await axios.post(
                base_url + "/auth",
                {},
                {
                    withCredentials: true,
                    httpOnly: false,
                    sameSite: "none",
                    secure: true
                }
            );
            const { status, user } = data;
            setUsername(user);
            if (status) {
                toast(`Hello ${user}`, {
                    position: "top-right",
                });
            } else {
                removeCookie("token");
                navigate("/login");
            }
        };
        verifyCookie();
    }, [cookies, navigate, removeCookie, searchParams]);

    const Logout = () => {
        removeCookie("token");
        navigate("/signup");
    };

    //TODO: Basic excell functionality but can push up to cloud or local short term db

    return (
        <div className='sheet-container'>
            <h1>Sheet</h1>
            <div className="content">
                <p>Welcome to the Sheet page, {username}!</p>
                <p>This is a new page with authentication checks.</p>
            </div>
            <div className='button-container'>
                <button onClick={() => navigate("/home")}>Back to Home</button>
                <button onClick={Logout}>Logout</button>
            </div>
        </div>
    );
};

export default Sheet;
