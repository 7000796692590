import '../App.css';
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import { toast } from "react-toastify";
const redirect_uri = process.env.NODE_ENV == "production" ? "https://peter-lee-gifford.com" : "http://localhost:3000"
const base_url = process.env.NODE_ENV == "production" ? "https://hooks-28xd.onrender.com" : "http://localhost:4000"


const Home = () => {
    const navigate = useNavigate();
    const [cookies, removeCookie] = useCookies([]);
    const [username, setUsername] = useState("");
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const verifyCookie = async () => {
            if (!cookies.token) {
                navigate("/login");
            }
            const { data } = await axios.post(
                base_url + "/auth",
                {},
                {
                    withCredentials: true,
                    httpOnly: false,
                    sameSite: "none",
                    secure: true
                }
            );
            const { status, user } = data;
            setUsername(user);
            if (status) {
                toast(`Hello ${user}`, {
                    position: "top-right",
                });
            } else {
                removeCookie("token");
                navigate("/login");
            }
        };
        verifyCookie();
    }, [cookies, navigate, removeCookie, searchParams]);

    const Logout = () => {
        removeCookie("token");
        navigate("/signup");
    };

    return (
        <div className='home-container'>
            <h1>Home</h1>
            <div className='button-container'>
                <button onClick={() => navigate("/tss")}>Tss</button>
                <button onClick={() => navigate("/map")}>Map</button>
                <button onClick={() => navigate("/rank")}>FriendRank</button>
                <button onClick={() => navigate("/video")}>Video</button>
                <button onClick={() => navigate("/custom")}>Custom</button>
                <button onClick={() => navigate("/sheet")}>Sheet</button>
                <button onClick={Logout}>Logout</button>
            </div>
        </div>
    )
};

export default Home;