import '../App.css';
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import { toast } from "react-toastify";

const base_url = process.env.NODE_ENV === "production" ? "https://hooks-28xd.onrender.com" : "http://localhost:4000";

const FriendRank = () => {
    const navigate = useNavigate();
    const [cookies, removeCookie] = useCookies([]);
    const [username, setUsername] = useState("");
    const [searchParams] = useSearchParams();
    const [friends, setFriends] = useState([]);
    const [newFriendName, setNewFriendName] = useState("");
    const [newFriendPizzaCount, setNewFriendPizzaCount] = useState(0);

    const sortedFriends = friends.sort((a, b) => b.pizzaCount - a.pizzaCount);

    useEffect(() => {
        const verifyCookie = async () => {
            if (!cookies.token) {
                navigate("/login");
            }
            const { data } = await axios.post(
                base_url + "/auth",
                {},
                {
                    withCredentials: true,
                    httpOnly: false,
                    sameSite: "none",
                    secure: true
                }
            );
            const { status, user } = data;
            setUsername(user);
            if (status) {
                toast(`Hello ${user}`, {
                    position: "top-right",
                });
            } else {
                removeCookie("token");
                navigate("/login");
            }
        };
        verifyCookie();
    }, [cookies, navigate, removeCookie, searchParams]);

    useEffect(() => {
        const fetchFriends = async () => {
            try {
                const { data } = await axios.get(base_url + "/pizza/");
                if (data.success) {
                    setFriends(data.pizzaPeople);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchFriends();
    }, []);

    const Logout = () => {
        removeCookie("token");
        navigate("/signup");
    };

    const incrementPizzaCount = (index) => {
        const updatedFriends = [...friends];
        updatedFriends[index].pizzaCount += 1;
        setFriends(updatedFriends);
    };

    const decrementPizzaCount = (index) => {
        const updatedFriends = [...friends];
        if (updatedFriends[index].pizzaCount > 0) {
            updatedFriends[index].pizzaCount -= 1;
            setFriends(updatedFriends);
        }
    };

    const addNewFriend = async () => {
        try {
            const { data } = await axios.post(base_url + "/pizza/", {
                name: newFriendName,
                pizzaCount: newFriendPizzaCount
            });
            if (data.success) {
                setFriends([...friends, data.pizzaPerson]);
                setNewFriendName("");
                setNewFriendPizzaCount(0);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='friend-rank'>
            <h1>Friend Pizza Rankings</h1>
            <div className='friend-list'>
                {sortedFriends.map((friend, index) => (
                    <div key={index} className='friend-item'>
                        <span className='friend-name'>{friend.name}</span>
                        <span className='friend-pizza-count'>{friend.pizzaCount} pizzas</span>
                        <button onClick={() => incrementPizzaCount(index)}>+1 Pizza</button>
                        <button onClick={() => decrementPizzaCount(index)}>-1 Pizza</button>
                    </div>
                ))}
            </div>
            <div className='new-friend'>
                <input
                    type="text"
                    placeholder="Name"
                    value={newFriendName}
                    onChange={(e) => setNewFriendName(e.target.value)}
                />
                <input
                    type="number"
                    placeholder="Pizza Count"
                    value={newFriendPizzaCount}
                    onChange={(e) => setNewFriendPizzaCount(parseInt(e.target.value))}
                />
                <button onClick={addNewFriend}>Add Friend</button>
            </div>
            <button onClick={Logout}>Logout</button>
        </div>
    )
};

export default FriendRank;